import React, { useState, useEffect } from "react";
import axios from "axios";
import { ADD_MESSAGE } from './../actions/types'
import { useDispatch } from 'react-redux';
import Select from 'react-select';

const CustomerInvoiceForm = () => {
    const [invoices, setInvoices] = useState([]);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [formInvoice, setFormInvoice] = useState({
        bill_to: "",
        bill_from: "",
        address: "",
        invoice_no: "",
        invoice_date: "",
        due_date: "",
        subject: "",
        currency: "USD",
        tax_included: false,
        total: 0,
        due_amount: "",
        paid_amount: "",
        notes_terms: "",
        bill_type: "customer",
        email: '',
        contact_number: '',
        items: [{ item_name: "", description: "", quantity: 1, price: "", amount: "" }]
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [sendingEmailFor, setSendingEmailFor] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteInvoiceId, setDeleteInvoiceId] = useState(null);
    const [invoiceLoading, setInvpoceLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        fetchInvoices();
        // fetchStudentData();
        fetchCombinedData();

    }, []);

    const fetchInvoices = async () => {
        const token = localStorage.getItem('access');
        setInvpoceLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}api/invoices/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            setInvoices(response.data);
        } catch (error) {
            console.error("Error fetching invoices:", error);
        } finally {
            setInvpoceLoading(false);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;

        if (name === "bill_to") {
            const selectedStudent = combinedData.find(student => student.name === value);
            if (selectedStudent) {
                const fullAddress = [
                    selectedStudent?.street_address,
                    selectedStudent?.city,
                    selectedStudent?.state,
                    selectedStudent?.country,
                    selectedStudent?.post_code
                ].filter(Boolean).join(', ');

                setFormInvoice({
                    ...formInvoice,
                    [name]: value,
                    email: selectedStudent.email,
                    address: fullAddress,
                    contact_number: selectedStudent.number
                });
            } else {
                setFormInvoice({ ...formInvoice, [name]: value });
            }
        } else {
            setFormInvoice({ ...formInvoice, [name]: value });
        }
    };

    const handleItemChange = (index, e) => {
        const { name, value } = e.target;
        const items = [...formInvoice.items];
        items[index][name] = value;
        if (name === "quantity" || name === "price") {
            items[index]["amount"] = items[index]["quantity"] * items[index]["price"];
        }
        setFormInvoice({ ...formInvoice, items });
    };

    const handleAddItem = () => {
        setFormInvoice({
            ...formInvoice,
            items: [...formInvoice.items, { item_name: "", description: "", quantity: 1, price: "", amount: "" }]
        });
    };

    const handleRemoveItem = (index) => {
        const items = formInvoice.items.filter((_, i) => i !== index);
        setFormInvoice({ ...formInvoice, items });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('access');

        if (selectedInvoice) {
            try {
                const response = await axios.put(`${process.env.REACT_APP_BASE_URL}api/invoices/${selectedInvoice.id}/`, formInvoice, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                dispatch({
                    type: ADD_MESSAGE,
                    payload: { msg: "Invoice updated successfully!", status: response.status }
                });
            } catch (err) {
                console.error("Error updating invoice:", err);
                dispatch({
                    type: ADD_MESSAGE,
                    payload: { msg: "Failed to update invoice", status: err.response?.status || 500 }
                });
            }
        } else {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/invoices/`, formInvoice, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                dispatch({
                    type: ADD_MESSAGE,
                    payload: { msg: "Invoice added successfully!", status: response.status }
                });
            } catch (err) {
                console.error("Error adding invoice:", err);
                dispatch({
                    type: ADD_MESSAGE,
                    payload: { msg: "Failed to create invoice", status: err.response?.status || 500 }
                });
            }
        }
        fetchInvoices();
        setFormInvoice({
            bill_to: "",
            bill_from: "",
            address: "",
            invoice_no: "",
            invoice_date: "",
            due_date: "",
            subject: "",
            currency: "USD",
            tax_included: false,
            total: 0,
            due_amount: "",
            paid_amount: "",
            notes_terms: "",
            bill_type: "customer",
            email: '',
            contact_number: '',
            items: [{ item_name: "", description: "", quantity: 1, price: "", amount: "" }]
        });
        setSelectedInvoice(null);
        closeModal();
    };

    const handleEdit = (invoice) => {
        setSelectedInvoice(invoice);
        setFormInvoice(invoice);
        openModal();
    };

    const handleDeleteConfirmation = (id) => {
        setDeleteInvoiceId(id);
        setIsDeleteModalOpen(true);
    };

    const handleDelete = async () => {
        const token = localStorage.getItem('access');
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}api/invoices/${deleteInvoiceId}/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            fetchInvoices();
            setIsDeleteModalOpen(false);
        } catch (error) {
            console.error("Error deleting invoice:", error);
        }
    };


    const handleSendEmail = async (invoiceId) => {
        setSendingEmailFor(invoiceId);
        const token = localStorage.getItem('access');
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/invoices/${invoiceId}/send_email/`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: "Email sent successfully!", status: response.status }
            });
        } catch (err) {
            console.error("Error sending email:", err);
            dispatch({
                type: ADD_MESSAGE,
                payload: { msg: "Failed to send email", status: err.response?.status || 500 }
            });
        } finally {
            setSendingEmailFor(null);
        }
    };


    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setFormInvoice({
            bill_to: "",
            bill_from: "",
            address: "",
            invoice_no: "",
            invoice_date: "",
            due_date: "",
            subject: "",
            currency: "USD",
            tax_included: false,
            total: 0,
            due_amount: "",
            paid_amount: "",
            notes_terms: "",
            bill_type: "customer",
            email: '',
            contact_number: '',
            items: [{ item_name: "", description: "", quantity: 1, price: "", amount: "" }]
        });
        setSelectedInvoice(null);
    };

    const [loading, setLoading] = useState(false);
    const [combinedData, setCombinedData] = useState([]);
    const fetchCombinedData = async () => {
        setLoading(true);
        try {
            // Fetch both student and job support data simultaneously
            const [studentResponse, jobSupportResponse] = await Promise.all([
                axios.get('https://stms.qatekinternal.com/records/partner-api/student-list/', {
                    headers: { Authorization: `Bearer ${localStorage.getItem('access')}` },
                }),
                axios.get('https://stms.qatekinternal.com/job-support/partner-api/job-support-list/', {
                    headers: { Authorization: `Bearer ${localStorage.getItem('access')}` },
                }),
            ]);

            const combined = [
                ...studentResponse.data,
                ...jobSupportResponse.data.records,
            ]
                .filter(item => item.name)
                .map(item => ({
                    name: item.name || item.name || '',
                    email: item.email || item.email || '',
                    number: item.phone || item.phone_number || '',
                    street_address: item?.job_support_record_address?.street_address || item.student_address?.street_address || '',
                    city: item?.job_support_record_address?.city || item.student_address?.city || '',
                    state: item?.job_support_record_address?.state || item.student_address?.state || '',
                    country: item?.job_support_record_address?.country || item.student_address?.country || '',
                    post_code: item?.job_support_record_address?.post_code || item.student_address?.post_code || '',
                }));

            setCombinedData(combined);
            console.log('Combined Data:', combined);
        } catch (error) {
            console.error('Error fetching data:', error);
            setCombinedData([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCombinedData();
    }, []);


    const options = combinedData.map(student => ({
        value: student.name,
        label: student.name
    }));

    // Handle change event for react-select
    const handleSelectChange = selectedOption => {
        handleChange({ target: { name: 'bill_to', value: selectedOption.value } });
    };

    const styles = {
        container: {
            fontFamily: 'Arial, sans-serif',
            margin: '0 auto'
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px'
        },
        formHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'sticky',
            top: -20,
            marginBottom: '10px',
            backgroundColor: '#fff',
            zIndex: 10,
            padding: '0 10px',
            boxShadow: '0 2px 5px rgba(0,0,0,0.1)'
        },
        button: {
            padding: '10px 20px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
        },
        modal: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
            opacity: isModalOpen ? 1 : 0,
            pointerEvents: isModalOpen ? 'auto' : 'none',
            transition: 'opacity 0.3s ease'
        },
        modalContent: {
            backgroundColor: 'white',
            borderRadius: '5px',
            padding: '20px',
            width: '80%',
            maxWidth: '600px',
            maxHeight: '80%',
            overflow: 'auto'
        },
        close: {
            cursor: 'pointer',
            fontSize: '24px',
            fontWeight: 'bold',
            color: 'red'
        },
        formGroup: {
            marginBottom: '15px'
        },
        label: {
            display: 'block',
            marginBottom: '5px'
        },
        input: {
            width: '100%',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '5px'
        },
        select: {
            width: '100%',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '5px'
        },
        textarea: {
            width: '100%',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '5px'
        },
        itemGroup: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '10px'
        },
        itemInput: {
            marginBottom: '10px'
        },
        removeButton: {
            padding: '5px 10px',
            backgroundColor: 'red',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            alignSelf: 'flex-end'
        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
            marginTop: '20px'
        },
        th: {
            border: '1px solid #ddd',
            padding: '8px',
            backgroundColor: '#f2f2f2'
        },
        td: {
            border: '1px solid #ddd',
            padding: '8px'
        },
        invoiceButton: {
            marginRight: '10px'
        }
    };


    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <button style={styles.button} onClick={openModal}>Add Invoice</button>
            </div>

            {/* Modal */}
            <div style={styles.modal}>
                <div style={styles.modalContent}>
                    <div style={styles.formHeader}>
                        <h3>{selectedInvoice ? "Edit Invoice" : "Add Invoice"}</h3>
                        <span style={styles.close} onClick={closeModal}>&times;</span>
                    </div>
                    <form onSubmit={handleSubmit}>

                        <div style={styles.formGroup}>
                            <label style={styles.label}>Bill To:</label>
                            <Select
                                name="bill_to"
                                value={options.find(option => option.value === formInvoice.bill_to)}
                                onChange={handleSelectChange}
                                options={options}
                                isLoading={loading}
                                placeholder="Select Bill To"
                            />
                        </div>

                        <div style={styles.formGroup}>
                            <label style={styles.label}>Bill From:</label>
                            <input style={styles.input} type="text" name="bill_from" value={formInvoice.bill_from} onChange={handleChange} required />
                        </div>


                        <div style={styles.formGroup}>
                            <label style={styles.label}>Recipient Address</label>
                            <textarea
                                style={styles.input}
                                name="address"
                                value={formInvoice.address}
                                onChange={handleChange}
                            />
                        </div>

                        <div style={styles.formGroup}>
                            <label style={styles.label}>Recipient Email</label>
                            <input style={styles.input} name="email" value={formInvoice.email} onChange={handleChange} />
                        </div>


                        <div style={styles.formGroup}>
                            <label style={styles.label}>Contact Number</label>
                            <input style={styles.input} name="contact_number" value={formInvoice.contact_number} onChange={handleChange} />
                        </div>


                        <div style={styles.formGroup}>
                            <label style={styles.label}>Invoice Number:</label>
                            <input
                                type="text"
                                name="invoice_no"
                                value={formInvoice.invoice_no}
                                onChange={handleChange}
                                style={styles.input}
                                required
                            />
                        </div>
                        <div style={styles.formGroup}>
                            <label style={styles.label}>Invoice Date:</label>
                            <input
                                type="date"
                                name="invoice_date"
                                value={formInvoice.invoice_date}
                                onChange={handleChange}
                                style={styles.input}
                                required
                            />
                        </div>
                        <div style={styles.formGroup}>
                            <label style={styles.label}>Due Date:</label>
                            <input
                                type="date"
                                name="due_date"
                                value={formInvoice.due_date}
                                onChange={handleChange}
                                style={styles.input}
                                required
                            />
                        </div>

                        <div style={styles.formGroup}>
                            <label style={styles.label}>Currency:</label>
                            <select
                                name="currency"
                                value={formInvoice.currency}
                                onChange={handleChange}
                                style={styles.select}
                                required
                            >
                                <option value="USD">USD</option>
                                <option value="EUR">EUR</option>
                                <option value="AUD">AUD</option>
                                <option value="BDT">BDT</option>
                                <option value="JPY">JPY</option>
                                <option value="GBP">GBP</option>
                                <option value="CAD">CAD</option>
                                <option value="CHF">CHF</option>
                                <option value="CNY">CNY</option>
                                <option value="SEK">SEK</option>
                                <option value="NZD">NZD</option>
                                <option value="MXN">MXN</option>
                                <option value="SGD">SGD</option>
                                <option value="HKD">HKD</option>
                                <option value="NOK">NOK</option>
                                <option value="KRW">KRW</option>
                                <option value="TRY">TRY</option>
                                <option value="RUB">RUB</option>
                                <option value="INR">INR</option>
                                <option value="BRL">BRL</option>
                                <option value="ZAR">ZAR</option>

                            </select>
                        </div>


                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                            <label style={{ marginRight: '10px' }}>Tax Included :</label>
                            <input type="checkbox" name="tax_included" checked={formInvoice.tax_included} onChange={(e) => setFormInvoice({ ...formInvoice, tax_included: e.target.checked })} />
                        </div>


                        <div style={styles.formGroup}>
                            <label style={styles.label}>Total:</label>
                            <input
                                type="text"
                                name="total"
                                value={formInvoice.total}
                                onChange={handleChange}
                                style={styles.input}
                                required
                            />
                        </div>

                        <div style={styles.formGroup}>
                            <label style={styles.label}>Due</label>
                            <input style={styles.input} type="number" name="due_amount" value={formInvoice.due_amount} onChange={handleChange} />
                        </div>

                        <div style={styles.formGroup}>
                            <label style={styles.label}>Paid</label>
                            <input style={styles.input} type="number" name="paid_amount" value={formInvoice.paid_amount} onChange={handleChange} />
                        </div>

                        <div style={styles.formGroup}>
                            <label style={styles.label}>Notes/Terms:</label>
                            <textarea
                                name="notes_terms"
                                value={formInvoice.notes_terms}
                                onChange={handleChange}
                                style={styles.textarea}
                            />
                        </div>
                        <div>
                            <label style={styles.label}>Items:</label>
                            {formInvoice.items.map((item, index) => (
                                <div key={index} style={styles.itemGroup}>
                                    <div style={styles.itemInput}>
                                        <input
                                            type="text"
                                            name="item_name"
                                            placeholder="Item Name"
                                            value={item.item_name}
                                            onChange={(e) => handleItemChange(index, e)}
                                            style={styles.input}
                                            required
                                        />
                                    </div>
                                    <div style={styles.itemInput}>
                                        <textarea
                                            name="description"
                                            placeholder="Description"
                                            value={item.description}
                                            onChange={(e) => handleItemChange(index, e)}
                                            style={styles.textarea}
                                            required
                                        />
                                    </div>
                                    <div style={styles.itemInput}>
                                        <input
                                            type="number"
                                            name="quantity"
                                            placeholder="Quantity"
                                            value={item.quantity}
                                            onChange={(e) => handleItemChange(index, e)}
                                            style={styles.input}
                                            required
                                        />
                                    </div>
                                    <div style={styles.itemInput}>
                                        <input
                                            type="number"
                                            name="price"
                                            placeholder="Price"
                                            value={item.price}
                                            onChange={(e) => handleItemChange(index, e)}
                                            style={styles.input}
                                            required
                                        />
                                    </div>
                                    <div style={styles.itemInput}>
                                        <input
                                            type="number"
                                            name="amount"
                                            placeholder="Amount"
                                            value={item.amount}
                                            readOnly
                                            style={styles.input}
                                            required
                                        />
                                    </div>
                                    <button type="button" style={styles.removeButton} onClick={() => handleRemoveItem(index)}>Remove</button>
                                </div>
                            ))}
                            <button type="button" style={styles.button} onClick={handleAddItem}>Add Item</button>
                        </div>
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            <button type="submit" style={styles.button}>
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <table style={styles.table}>
                <thead>
                    <tr>
                        <th style={styles.th}>Invoice Number</th>
                        <th style={styles.th}>Bill To</th>
                        <th style={styles.th}>Bill From</th>
                        <th style={styles.th}>Invoice Date</th>
                        <th style={styles.th}>Due Date</th>
                        <th style={styles.th}>Paid</th>
                        <th style={styles.th}>Due</th>
                        <th style={styles.th}>Total</th>
                        <th style={styles.th}>Actions</th>
                    </tr>
                </thead>

                {invoiceLoading ? (
                    <p>Loading...</p>
                ) : (
                    <tbody>
                        {invoices.filter(invoice => invoice.bill_type === "customer").map((invoice) => (
                            <tr key={invoice.id}>
                                <td style={styles.td}>{invoice.invoice_no}</td>
                                <td style={styles.td}>{invoice.bill_to}</td>
                                <td style={styles.td}>{invoice.bill_from}</td>
                                <td style={styles.td}>{invoice.invoice_date}</td>
                                <td style={styles.td}>{invoice.due_date}</td>
                                <td style={styles.td}>{invoice.paid_amount}</td>
                                <td style={styles.td}>{invoice.due_amount}</td>
                                <td style={styles.td}>{invoice.total}</td>
                                <td style={styles.td}>
                                    <button
                                        style={{ ...styles.button, ...styles.invoiceButton }}
                                        onClick={() => handleEdit(invoice)}
                                    >
                                        Edit
                                    </button>
                                    <button
                                        style={{ ...styles.button, ...styles.invoiceButton }}
                                        onClick={() => handleDeleteConfirmation(invoice.id)}
                                    >
                                        Delete
                                    </button>


                                    <button
                                        style={{
                                            ...styles.button,
                                            ...styles.invoiceButton,
                                            backgroundColor: sendingEmailFor === invoice.id ? '#6c757d' : '#007bff',
                                            cursor: sendingEmailFor === invoice.id ? 'not-allowed' : 'pointer'
                                        }}
                                        onClick={() => handleSendEmail(invoice.id)}
                                        disabled={sendingEmailFor === invoice.id}
                                    >
                                        {sendingEmailFor === invoice.id ? 'Sending...' : 'Send Email'}
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                )}

            </table>

            {isDeleteModalOpen && (
                <div className="modal" style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                    width: '100%',
                    height: '100%'
                }}>
                    <div className="modal-content" style={{
                        backgroundColor: 'white',
                        borderRadius: 5,
                        padding: 20,
                        maxWidth: 300,
                        textAlign: 'center',
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                        transition: '0.3s'
                    }}>
                        <h2 style={{ marginBottom: 10 }}>Delete Invoice</h2>
                        <p style={{ marginBottom: 20 }}>Are you sure you want to delete this invoice?</p>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <button
                                onClick={handleDelete}
                                style={{
                                    backgroundColor: '#f44336',
                                    color: 'white',
                                    padding: '10px 20px',
                                    borderRadius: 5,
                                    border: 'none',
                                    cursor: 'pointer',
                                    marginRight: 10
                                }}
                            >
                                Delete
                            </button>
                            <button
                                onClick={() => setIsDeleteModalOpen(false)}
                                style={{
                                    backgroundColor: '#ccc',
                                    color: 'black',
                                    padding: '10px 20px',
                                    borderRadius: 5,
                                    border: 'none',
                                    cursor: 'pointer'
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomerInvoiceForm;